import { graphql } from 'gatsby';
import NotFound from 'pages/404';
import * as React from "react";

import Content from "components/templates/Content";
import Layout from 'components/templates/Layout';
import SimpleViewForm from "components/templates/global/SimpleViewForm";

import ContentCards from "components/templates/global/ContentCards";
import Crowdriff from "components/templates/global/Crowdriff";
import { FeaturedContentSlider } from "components/templates/global/FeaturedContentSlider";
import LatestNews from "components/templates/global/LatestNews";
import Neighborhoods from "components/templates/global/Neighborhoods";
import SimpleGrid from "components/templates/global/SimpleGrid";
import Link from 'components/link';
import EventSubmissionForm from "components/templates/global/EventSubmissionForm";

import useLiveData from 'hooks/useLiveData';

const ContentPage = (props) => {
	let posts = []
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	const { statusText: providerStatusText, status: providerStatus, response: providerResponse } = useLiveData({
		type: 'ContentPage',
		apiParams: {
			uri: props.location.pathname,
			_join: "Post",
		},
		staticData: props.data,
	});
	let page = nodes[0];

	if (providerStatus < 2) return null; // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	if ((providerResponse.data.length > 0 || !!page !== false) && providerStatusText === 'LOADED') {
		page = providerResponse.data[0];
	}
	if (!!page === false && providerStatus >= 2) return <NotFound />;

	if (page.contentRepeater && page.contentRepeater.length > 0) {
		page.contentRepeater = page.contentRepeater.map((content) => {
			content['description'] = content.body
			return content;
		})
	}

	if (page?.Post && page.Post.length > 0) {
		posts = page.Post.map(({ object }) => object).slice(0, 3);
	}

	const featuredContent2 = {
		title: page.fcTitle2,
		body: page.fcBody2,
		image: page.fcImage2,
		link: page.fcLink2,
		linkText: page.fcLinkText2,
	}

	const featuredContent3 = {
		title: page.fcTitle3,
		body: page.fcBody3,
		image: page.fcImage3,
		link: page.fcLink3,
		linkText: page.fcLinkText3,
	}

	return (
		<div className={`loadWrap ${providerStatus >= 2 ? 'loaded' : ''}`}>
			<Layout headerBackgroundImage={page?.headlineImage}>
				{providerStatus >= 2 &&
					<>
						<Content
							preContent={
								<div className='grid-container'>
									<div className='grid-x grid-padding-x align-center'>
										{page?.mwCards && (
											<>
												<div className='cell small-12 text-center'>
													{page?.mwCardHeader && <h2 className='meetingsWeddingsHeader' style={{ paddingBottom: "2.25rem" }}>{page?.mwCardHeader}</h2>}
												</div>
												<div className='cell small-12'>
													<div className="grid-x align-center grid-padding-x small-up-1 medium-up-2">
														{page?.mwCards.map((card, index) => (
															<div key={`${index}-${decodeURIComponent(card.link)}`} className={`cell`} style={{ marginBottom: '30px' }}>
																<div className="news-card flex-container flex-dir-column align-items">
																	<div className="news-photo" style={{ backgroundImage: `url(${card.image})` }}></div>
																	<div className="news-card-content">
																	</div>
																	<Link className="button blue" to={`${card.link}`}>{(card.linkText) ? card.linkText : 'Read More'}</Link>
																</div>
															</div>
														))}
													</div>
												</div>
											</>
										)}
										{(page.formPosition === "before" && page.formID) && <SimpleViewForm formID={page.formID} />}
										{props.location.pathname === "/explore/" && <Neighborhoods />}
										{!!page?.contentRepeater && <FeaturedContentSlider slides={page.contentRepeater} schema={{ side: "right", color: "alternate", imageSize: "small" }} />}
									</div>
								</div>
							}
							headlineTitle={page?.headlineTitle}
							headlineBody={page?.headlineBody}
							headlineImage={page?.headlineImage}
							page={page}
							postContent={
								<>
									{props.location.pathname === "/submit-event/" && <EventSubmissionForm />}
									{(page.formPosition === "after" && page.formID) && <SimpleViewForm formID={page.formID} />}
									{!!page.fcBody2 && <SimpleGrid {...featuredContent2} />}
									{!!page?.cards && <ContentCards cards={page.cards} />}
									{!!page.fcBody3 && <SimpleGrid styleContent={true} imageFirst={true} {...featuredContent3} />}
									<LatestNews posts={posts} />
									{page?.crowdriff && <Crowdriff hash={page?.crowdriff} />}
								</>
							}
						/>
					</>
				}
			</Layout>
		</div>
	)
}

export default ContentPage;

export { Head } from 'components/templates/Head';

export const query = graphql`
query contentPageQuery ($uri: String) {
	allContentPage(
	  filter: {uri: {eq: $uri}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  
		  content {
			main
		  }

		  headlineBody
		  headlineTitle
		  headlineImage

		  fcTitle2
		  fcBody2
		  fcImage2
		  fcLink2
		  fcLinkText2

		  cards
  
		  contentRepeater
		  contentRepeater2
		  contentRepeater3
		  
		  formID
		  formPosition

		  crowdriff

		  mwCardHeader
		  mwCards

		  Post {
			object {
				id
				permalink
				title
				photo
				body
				uri
				showDate
				intro
			}
		  }
		}
	  }
	}
  }
`
import { useFormik } from 'formik';
import { graphql, navigate, useStaticQuery } from "gatsby";
import * as React from "react";
import * as Yup from 'yup';
//import { Alert } from '@mui/material';
import Button from '@mui/material/Button';

import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import { TimePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Link from 'components/link';

const EventSubmissionForm = () => {

	const data = useStaticQuery(graphql`
    {
      allEventsCategory {
        edges {
          node {
            name
            permalink
          }
        }
      }
    }
  `)

	function americanDate(datetime) {
		var month = datetime.getMonth() + 1;
		var day = datetime.getDate();
		var year = datetime.getFullYear();
		if (day < 10) day = '0' + day;
		return month + '/' + day + '/' + year;
	}

	function militarize(time) {
		var period = time.match('pm') ? 'pm' : 'am';
		time = time.replace(' am', '').replace(' pm', '').split(':');
		let hour, min = '';
		if (period === 'am') {
			hour = time[0];
			min = time[1];
		} else if (period === 'pm') {
			hour = 12 + parseInt(time[0], 10)
			min = time[1];
		}
		return hour + ':' + min + ':00';
	}

	function convertMilitary(datetime) {
		if (typeof datetime === 'string') {
			var days = ['Sun', 'Mon', 'Tue', 'Thu', 'Fri', 'Sat'];
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			datetime = militarize(datetime);
			var now = new Date();
			datetime = days[now.getDay()] + ' ' + months[now.getMonth()] + ' ' + now.getDate() + ' ' + datetime;
			return new Date(datetime);
		} else {
			var startHours = datetime.getHours();
			var period = '';
			if (startHours === 0) {
				startHours = String(12);
				period = 'am';
			} else if (startHours > 12) {
				startHours = String(startHours - 12);
				period = 'pm';
			} else if (startHours < 12) {
				startHours = String(startHours);
				period = 'am';
			} else {
				startHours = '12';
				period = 'pm';
			}
			var startMinutes = String(datetime.getMinutes());
			if (startMinutes === '0') startMinutes = '00';
			var formattedStartTime = String(startHours) + ':' + String(startMinutes) + ' ' + period;
			return formattedStartTime;
		}
	}

	var autocorrect = function (value) {
		//var match = null;
		var timePreCheckExp = /(?<hour>\d{1,2})(?<minute>:\d{2})?\s?(?<period>a|p)\.?(?:m?\.?)(?<end>[^0-9]+|$)/ig;
		var timeExp = /(?<hour>\d{1,2})(?<minute>:\d{2})?\s?(?:(?<period>a|p)\.?(?:m?\.?))?(?<end>[^0-9]+|$)/ig;
		var dash = /-|\u2011|\u2012|\u2013|\u2014|\u2015/;
		var space = /^\s+$/;
		if (timePreCheckExp.test(value)) {
			var counter = 0;
			value = value.replace(/\s?(-|to)\s?/ig, '-');
			value = value.replace(/\sand\s/ig, ' & ');
			while (timeExp.test(value) && counter <= value.length) {
				value = value.replace(timeExp, function (match, hour, minute, period, end, offset, string) {
					var replacement = hour;
					if (minute) replacement += (minute !== ':00' ? minute : '');
					if (end.length) {
						if (dash.test(end) || space.test(end)) end = '-';
						else if (end.indexOf(',') !== -1 || end.indexOf(';') !== -1) end = ', ';
					}
					if (period) {
						replacement += period.toUpperCase() + 'M';
					}
					replacement += end;
					return replacement;
				});
				counter++;
			}
			var split = value.split('-');
			if (split.length > 0) {
				for (var i = 0; i < split.length; i++) {
					if (i < split.length - 1) {
						var periodExp = /(?<period>a|p)\.?(?:m?\.?)(?<end>\s|$|-)/i;
						var thisMatch = periodExp.exec(split[i]);
						var nextMatch = periodExp.exec(split[i + 1]);
						if (thisMatch && nextMatch && thisMatch[0] === nextMatch[0]) {
							split[i] = split[i].replace(periodExp, '');
						}
					}
				}
			}
			value = split.join('-');
			value = value.replace(/-+\s+/, '-');
		}
		return value;
	};

	const CategoryOptions = data.allEventsCategory.edges.map(({ node }) => {
		return {
			label: node.name,
			value: node.permalink
		}
	})

	const neighborhoodOptions = [
		{ label: 'None', value: '' },
		{ label: 'DeFuniak Springs', value: 'defuniak-springs' },
		{ label: 'Freeport', value: 'freeport' },
		{ label: 'Paxton', value: 'paxton' },
	]

	const initialValues = {
		name: '',
		website: '',
		description: '',
		address: '',
		city: '',
		location: '',
		Category: [],
		dates: [],
		Neighborhood: '',
		cost: '',
		email: '',
		phone: '',
		poster: '',
		photo: '',
		contactName: '',
		contactEmail: '',
		contactPhone: ''
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Event Name is required'),
		website: Yup.string().url('Please enter a valid URL').required('Event Website is required'),
		dates: Yup.array().min(1, 'Event must have at least one date'),
		description: Yup.string().required('Event Description is required'),
		address: Yup.string().required('Street Address is required'),
		city: Yup.string().required('City is required'),
		location: Yup.string().required('Location is required'),
		Category: Yup.array().min(1, 'Event must have at least one type'),
		Neighborhood: Yup.string().required('Neighborhood is required'),
		cost: Yup.string().required('Admission Cost is required'),
		email: Yup.string().email('Please enter a valid email').required('Event Email is required'),
		phone: Yup.string().required('Event Phone is required'),
		poster: Yup.string(),
		photo: Yup.string(),
		contactName: Yup.string().required('Name is required'),
		contactEmail: Yup.string().email('Please enter a valid email').required('Email is required'),
		contactPhone: Yup.string().required('Phone is required')
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: (values, actions) => {

			const formData = new FormData();

			Object.keys(values).forEach((value) => {
				if (Array.isArray(values[value])) {
					formData.append(value, JSON.stringify(values[value]))
				} else {
					formData.append(value, values[value]);
				}
			})

			// POST to /submit-event/ with data
			fetch(`${process.env.GATSBY_ZMS_API_URL}submit-event/`, {
				method: "POST",
				body: formData,
			})
				.then(response => response.json())
				.then(response => {
					if (response.data.httpStatus !== 200) {
						formik.errors = {
							[response.data.field]: response.data.text
						}
						actions.setFieldError(response.data.field, response.data.text)
					} else {
						// if success, redirect to /submit-event/success
						navigate('/submit-event/success/');
					}
					actions.setSubmitting(false);
				})
				.catch((error) => {
					console.error('Error:', error);
					actions.setSubmitting(false);
				});
		},
	});

	const [dates, setDates] = React.useState([])
	const [datesSelected, setDatesSelected] = React.useState([])
	const [categoriesSelected, setCategoriesSelected] = React.useState([])
	const [neighborhoodSelected, setNeighborhoodSelected] = React.useState('')
	const [endTimeCheckValue, setEndTimeCheckValue] = React.useState(false);
	const [allDayEventCheckValue, setAllDayEventCheckValue] = React.useState(false);

	const eventDateRef = React.useRef(null);
	const eventEndTimeRef = React.useRef(null);

	const handleEndTimeCheck = (event) => {
		setEndTimeCheckValue(event.target.checked);
	}

	const handleAllDayCheck = (event) => {
		setAllDayEventCheckValue(event.target.checked);
		if (event.target.checked) {
			setEndTimeCheckValue(false);
		}
	}

	const handleCategoriesSelectChange = (event) => {
		const {
			target: { value },
		} = event;
		setCategoriesSelected(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
		formik.setFieldValue('Category', typeof value === 'string' ? value.split(',') : value)
	}

	const handleNeighborhoodSelectChange = (event) => {
		setNeighborhoodSelected(event.target.value);
		formik.setFieldValue('Neighborhood', event.target.value)
	}

	const handleDatesSelectChange = (event) => {
		const { options } = event.target;
		const value = [];
		for (let i = 0, l = options.length; i < l; i += 1) {
			if (options[i].selected) {
				value.push(options[i].value);
			}
		}

		setDatesSelected(value);
		formik.setFieldValue('dates', value);
	}

	const handlePosterUpload = (event) => {
		formik.setFieldValue('poster', event.target.files[0]);
	}
	const handlePhotoUpload = (event) => {
		formik.setFieldValue('photo', event.target.files[0]);
	}

	const insertDate = () => {
		let startDate = eventDateRef.current.value;
		let endTime = null;

		if (!startDate) {
			alert('Please select a date.')
			return;
		}

		if (endTimeCheckValue) {
			endTime = eventEndTimeRef.current.value;
		}

		let datetime = new Date(startDate);
		let startTime = null;
		let dateObject = {}

		if (allDayEventCheckValue) {
			startTime = null;
			dateObject = {
				"date": datetime,
				"startTime": "All Day"
			}
		} else {
			startTime = convertMilitary(datetime)
			dateObject = {
				"date": datetime,
				"startTime": startTime,
				"endTime": endTime
			}
		}

		let formDate = americanDate(datetime);

		// if this, there will also be a start time; an end time cannot be chosen without a start time.
		if (endTime) {
			formDate += ' (' + autocorrect(startTime + '-' + endTime) + ')';
			// already established there is no end time. so if there's a start time, it's the only time.
		} else if (startTime) {
			formDate += ' ' + autocorrect(startTime);
		} else {
			formDate += ' All Day';
		}

		setDates([...dates, { label: formDate, value: JSON.stringify(dateObject) }]);
		setDatesSelected([...dates, { label: formDate, value: JSON.stringify(dateObject) }].map((date) => (date.value)));
		formik.setFieldValue('dates', [...dates, { label: formDate, value: JSON.stringify(dateObject) }]);
		eventDateRef.current.value = '';
		eventEndTimeRef.current.value = '';
		setAllDayEventCheckValue(false)
		setEndTimeCheckValue(false);
	}

	const removeSelectedDates = () => {
		const selected = datesSelected;
		const newDates = dates.filter((date) => {
			return !selected.includes(date.value);
		});
		setDates(newDates);
		setDatesSelected(dates.map((date) => (date.value)));
		formik.setFieldValue('dates', newDates);
	}

	return (
		<div id="event-form" className='content-page'>
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<form onSubmit={formik.handleSubmit} id="form-submit" method="post" encType="multipart/form-data">
					<div className="grid-container">
						<div className="grid-x grid-padding-x align-center">
							<div className="cell small-12 large-10">
								<div className="h3">Event Information</div>
							</div>
							<div className="cell small-12 large-5">
								<TextField required fullWidth name="name" type="input" label="Event Name"
									//helperText={`Enter a descriptive, unique name for your event`}
									helperText={formik.errors.name ? formik.errors.name : "Enter a descriptive, unique name for your event"}
									value={formik.values.name}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.name && Boolean(formik.errors.name)}
								/>
							</div>
							<div className="cell small-12 large-5">
								<TextField required fullWidth name="website" type="input" label="Event Website"
									helperText={formik.errors.website ? formik.errors.website : "Link to more event information"}
									value={formik.values.website}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.website && Boolean(formik.errors.website)}
								/>
							</div>
							<div className="cell small-12 large-10">
								<hr />
								<h4>Add Event Date(s)</h4>
							</div>
							<div className="cell small-12 large-5 dates-area">
								<div className="widget-selection-area">
									<div className="classer">
										<FormHelperText>
											Enter date and time. Click "insert selected." Repeat for recurring events.
										</FormHelperText>
										<div>
											<FormControlLabel labelPlacement="end" control={<Checkbox checked={allDayEventCheckValue} onChange={handleAllDayCheck} />} label="All day event?" />
										</div>
										<DateTimePicker inputRef={eventDateRef} disabled={formik.isSubmitting} placeholder="Event Date" name="eventDate" disablePast={true} />
									</div>
									<div className="declasser">
										<div>
											<FormControlLabel labelPlacement="end" control={<Checkbox checked={endTimeCheckValue} onChange={handleEndTimeCheck} />} label="End Time (optional)" />
										</div>
										<TimePicker inputRef={eventEndTimeRef} disabled={!endTimeCheckValue} placeholder="End Time" name="eventEndTime" />
									</div>
								</div>
							</div>
							<div className="cell small-12 large-5">
								<FormControl error={formik.errors.dates} sx={{ width: "100%" }}>
									<InputLabel shrink htmlFor="dateList">Event Date(s)</InputLabel>
									<Select disabled={formik.isSubmitting} native required placeholder="Event Date(s)" name="dates[]" label="Event Date(s)" value={datesSelected} multiple={true}
										onChange={handleDatesSelectChange}
										inputProps={{
											id: 'dateList',
										}}
									>
										{dates.map((option, i) => {
											return <option key={i} value={option.value}>{option.label}</option>
										})}
									</Select>
									<FormHelperText>{formik.errors.dates ? formik.errors.dates : "Select all that apply"}</FormHelperText>
								</FormControl>
								<Link to={`#`} onClick={insertDate} className={`button space-right`}>Insert Selected</Link>
								<Link to={`#`} onClick={removeSelectedDates} className={`button ${dates.length ? "" : "disabled"}`}>Remove Selected</Link>
							</div>
							<div className="cell small-12 large-10  end">
								<hr />
								<TextField required sx={{ width: "100%", boxShadow: "none", border: "none" }} multiline={true} minRows={4} fullWidth name="description" type="input" label="Event Description" lines={4}
									helperText={formik.errors.description ? formik.errors.description : "Enter a short summary of your event"}
									value={formik.values.description}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.description && Boolean(formik.errors.description)}
								/>
							</div>
							<div className="cell small-12 large-5 location-details">
								<TextField required fullWidth name="address" type="input" label="Street Address"
									value={formik.values.address}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.address && Boolean(formik.errors.address)}
								/>
								<TextField required fullWidth name="city" type="input" label="City"
									value={formik.values.city}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.city && Boolean(formik.errors.city)}
								/>
							</div>
							<div className="cell small-12 large-5 location-details end">
								<TextField required fullWidth name="location" type="input" label="Location" lines={4}
									helperText={formik.errors.location ? formik.errors.location : "Consumer-facing location description. Can be a landmark, intersection, full address, etcetera."}
									value={formik.values.location}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.location && Boolean(formik.errors.location)}
								/>
							</div>
							<div className="cell small-12 large-5 ">
								<FormControl error={formik.errors.Category} sx={{ width: "100%" }}>
									<InputLabel id="CategoryLabel">Event Type(s) *</InputLabel>
									<Select disabled={formik.isSubmitting} onChange={handleCategoriesSelectChange} required labelId="CategoryLabel" id="Category" placeholder="Event Type(s) *" name="Category" label="Event Type(s) *" value={categoriesSelected} multiple={true}>
										{CategoryOptions.map((option, i) => {
											return <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
										})}
									</Select>
									<FormHelperText>
										{formik.errors.Category ? formik.errors.Category : "Select all that apply"}
									</FormHelperText>
								</FormControl>
							</div>
							<div className="cell small-12 large-5 end">
								<FormControl error={formik.errors.Neighborhood} sx={{ width: "100%" }}>
									<InputLabel id="NeighborhoodLabel">Neighborhood(s) *</InputLabel>
									<Select disabled={formik.isSubmitting} onChange={handleNeighborhoodSelectChange} required labelId="NeighborhoodLabel" id="Neighborhood" placeholder="Neighborhood(s) *" value={neighborhoodSelected} label="Neighborhood(s) *"
									>
										{neighborhoodOptions.map((option, i) => {
											return <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
										})}
									</Select>
									<FormHelperText>
										{formik.errors.Neighborhood ? formik.errors.Neighborhood : "Select all that apply"}
									</FormHelperText>
								</FormControl>
							</div>
							<div className="cell small-12 large-5 ">
								<TextField required fullWidth name="cost" type="input" label="Admission Cost"
									helperText={formik.errors.cost ? formik.errors.cost : "If no admission cost, type 'Free'"}
									value={formik.values.cost}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.cost && Boolean(formik.errors.cost)}
								/>
							</div>
							<div className="cell small-12 large-5 end">
								<TextField required fullWidth name="email" type="input" label="Event Email"
									helperText={formik.errors.email ? formik.errors.email : "Email will appear on event listing"}
									value={formik.values.email}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.email && Boolean(formik.errors.email)}
								/>
							</div>
							<div className="cell small-12 large-5  end">
								<TextField required fullWidth name="phone" type="input" label="Event Phone"
									helperText={formik.errors.phone ? formik.errors.phone : "Phone number will appear on event listing"}
									value={formik.values.phone}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.phone && Boolean(formik.errors.phone)}
								/>
							</div>
							<div className="cell small-12 large-5"></div>
							<div className="cell small-12 large-5">
								<FormControl error={formik.errors.photo} sx={{ width: "100%" }}>
									<Button disabled={formik.isSubmitting} error={formik.errors.photo} component="label" variant="text">
										<div className='labeltext'>Event Photo</div>
										<input onChange={handlePhotoUpload} name="photo" type="file" accept="images" />
										<strong>The Event Photo will be used in the preview and masthead for the event page on authenticwalton.com</strong>
									</Button>
									<FormHelperText>
										{formik.errors.photo ? (<strong style={{ fontSize: "1rem" }}>{formik.errors.photo}</strong>) : "Upload a 16:9 JPEG or PNG image with dimensions of at least 1920x1080 pixels, ensuring central content alignment, high resolution, and visual appeal of your event"}
									</FormHelperText>
								</FormControl>
							</div>
							<div className="cell small-12 large-5">
								<FormControl error={formik.errors.poster} sx={{ width: "100%" }}>
									<Button disabled={formik.isSubmitting} error={formik.errors.poster} component="label" variant="text">
										<div className='labeltext'>Event Poster</div>
										<input onChange={handlePosterUpload} name="poster" type="file" accept=".png,.jpg,.jpeg,.pdf" />
										<strong>If available, the Event Poster will be linked on the interior event page to provide additional information.</strong>
									</Button>
									<FormHelperText>
										{formik.errors.poster ? (<strong style={{ fontSize: "1rem" }}>{formik.errors.poster}</strong>) : "Upload a poster for your event"}
									</FormHelperText>
								</FormControl>
							</div>
							<div className="cell small-12 large-10">
								<br /><hr />
								<div className="h3">Submitter Information</div>
								<p>Information provided below will be used only by Walton County Tourism staff and not displayed to the public.</p>
							</div>
							<div className="cell small-12 large-5">
								<TextField required fullWidth name="contactName" type="input" label="Name"
									value={formik.values.contactName}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.contactName && Boolean(formik.errors.contactName)}
								/>
							</div>
							<div className="cell small-12 large-5">
								<TextField required fullWidth name="contactEmail" type="input" label="Email"
									value={formik.values.contactEmail}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
								/>
							</div>
							<div className="cell small-12 large-5  end">
								<TextField required fullWidth name="contactPhone" type="input" label="Phone"
									value={formik.values.contactPhone}
									onChange={formik.handleChange}
									disabled={formik.isSubmitting}
									onBlur={formik.handleBlur}
									error={formik.touched.contactPhone && Boolean(formik.errors.contactPhone)}
								/>
							</div>
							<div className="cell small-12 large-5">
							</div>
							<div className="cell small-12 medium-10 end text-center">
								<button type="submit" className="button submit-event-button" disabled={formik.isSubmitting}>
									{formik.isSubmitting ? 'Submitting...' : 'Submit Your Event'}
								</button>
							</div>
						</div>
					</div>
				</form>
			</LocalizationProvider>
		</div>

	);
};

export default EventSubmissionForm;